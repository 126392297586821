import { defineComponent as _defineComponent } from 'vue'
import GreenOptionsModule from "@ems/containers/GreenOptions/GreenOptions.module";
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import { object, string } from "yup";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactSJ.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const schema = object({
  Name: string().required().label("name"),
  Email: string().required().email().label("email"),
});

const onSubmit = async (values: any) => {
  await GreenOptionsModule.submitContractSJ(values);

  router.push("/setting-information/your-green-options");
};

const __returned__ = { router, schema, onSubmit, get GreenOptionsModule() { return GreenOptionsModule }, get Field() { return Field }, get Form() { return Form }, get useRouter() { return useRouter }, get object() { return object }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})