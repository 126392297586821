import HttpClient from "@/services/axios/instance";

import { catchAxiosError } from "@/services/axios/error";

import { IResponse } from "@/services/axios/entities";
import { IContactSJDTO } from "@/models/FormData";
import { getEnv } from "@/utils/storage";

const env = getEnv();
export class FormDataAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public submitContractSJ = async (data: IContactSJDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`contact-sj`, data)
      .catch(catchAxiosError);

    return response;
  };
}
